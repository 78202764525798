import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatUI.css';
import { useNavigate } from 'react-router-dom';


function ChatUI() {
    const [text, setText] = useState('');
    const [messages, setMessages] = useState([]);
    const [state, setState] = useState('INIT'); // Máquina de estado
    const [selectedCpf, setSelectedCpf] = useState(''); // Para armazenar o CPF selecionado
    const [phoneDigits, setPhoneDigits] = useState('');  // Para armazenar os 4 últimos dígitos
    const navigate = useNavigate();

    useEffect(() => {
        // Verificar se o token existe no local storage
        const token = localStorage.getItem('conarecToken');
        if (!token) {
            navigate('/login'); // Redirecionar para o login se não estiver autenticado
        }
    }, [navigate]);

    // Função para resetar todos os estados
    const resetStates = () => {
        setText('');
        setMessages([{ text: "Digite seu nome", sender: 'system' }]);
        setState('INIT');
        setSelectedCpf('');
    };

    const handleInputChange = (e) => {
        setText(e.target.value);
    };

    const handleLogout = () => {
        localStorage.removeItem('token'); // Remover o token do local storage
        navigate('/login'); // Redirecionar para a página de login
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('conarecToken');

        if (text.trim()) {
            const userMessage = { text: `${text}`, sender: 'user' };
            const loadingMessage = { text: "Carregando...", sender: 'system' };
            setMessages([...messages, userMessage, loadingMessage]);
            setText('');

            if (state === 'INIT') {
                try {
                    const response = await axios.post('https://api.nbxdata.com/dados/cpc/init_state/', {
                        name: text
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    if (response.status === 401) {
                        navigate('/login')
                    }

                    const { state: newState, message } = response.data;
                    setState(newState);

                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: message, sender: 'system' }
                    ]);
                } catch (error) {
                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: `Erro: ${(error.response?.data?.error || error.message)}`, sender: 'system' }
                    ]);
                }
            } else if (state === 'SELECT_CPF') {
                try {
                    const response = await axios.post('https://api.nbxdata.com/dados/cpc/verify_cpf_digits/', {
                        cpf_digits: text
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    const { state: newState, message } = response.data;
                    setState(newState);

                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: message, sender: 'system' }
                    ]);
                } catch (error) {
                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: `Erro: ${(error.response?.data?.error || error.message)}`, sender: 'system' }
                    ]);
                }
            } else if (state === 'VERIFY_PHONE') {
                try {
                    const response = await axios.post('https://api.nbxdata.com/dados/cpc/verify_phone_digits/', {
                        phone_digits: text  // Envia os últimos 4 dígitos para verificação
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    const { state: newState, message } = response.data;
                    setState(newState);

                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: message, sender: 'system' }
                    ]);
                } catch (error) {
                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: `Erro: ${(error.response?.data?.error || error.message)}`, sender: 'system' }
                    ]);
                }
            } else if (state === 'ASK_PHONE') {
                try {
                    const response = await axios.post('https://api.nbxdata.com/dados/cpc/ask_phone/', {
                        phone_number: text
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    const { state: newState, message } = response.data;
                    setState(newState);

                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: message, sender: 'system' }
                    ]);
                } catch (error) {
                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        { text: `Erro: ${(error.response?.data?.error || error.message)}`, sender: 'system' }
                    ]);
                }
            } else if (state === 'SELECT_PHONE') {
                // Lógica para quando o usuário tiver que digitar o telefone completo
                setMessages((prevMessages) => [
                    ...prevMessages.slice(0, -1),
                    { text: `Você digitou o telefone completo: ${text}`, sender: 'user' }
                ]);

                // Aqui você faria a chamada para a API com o número completo
            }
        }
    };

    return (
        <div className="chat-container">
            <button onClick={handleLogout} className="logout-button">Logout</button>
            <div className="message-container">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.sender === 'user' ? 'user-message' : 'system-message'}`}
                        dangerouslySetInnerHTML={{ __html: message.text }} // Renderiza o HTML para as quebras de linha
                    />
                ))}
            </div>

            <form onSubmit={handleSubmit} className="input-form">
                <div className="input-reset-container">
                    <button type="button" className="reset-button" onClick={resetStates}>
                        Resetar
                    </button>
                    <input
                        type="text"
                        value={text}
                        onChange={handleInputChange}
                        className="text-input"
                        placeholder="Digite sua mensagem..."
                    />
                </div>
            </form>
        </div>
    );
}

export default ChatUI;
